/**
 * CardOffice
 */

import React from 'react';
import classNames from 'classnames';
import s from './CardOffice.module.scss';

const CardOffice = ({
    title,
    streetAddress,
    zipCity,
    map,
    email,
    phone,
    center,
    linkTitle,
    href,
    image,
    shadow,
}) => {
    const hasImage = image && image.src;

    return (
        <div
            className={classNames(
                s['CardOffice'],
                {[s['CardOffice--Center']]: center},
                {[s['CardOffice--Shadow']]: shadow},
            )}>
            <div className={s['CardOffice__ContactInfoContainer']}>
                {hasImage && (
                    <div
                        className={s['CardOffice__Image']}
                        style={{ backgroundImage: `url('${image.src}')` }}
                    />
                )}
                {!hasImage && (
                    <div className={classNames(s['CardOffice__Image'], s['CardOffice__Image--Fallback'])} />
                )}
                {!linkTitle && (
                    <h3 className={s['CardOffice__ContactInfoTitle']}>{title}</h3>
                )}

                {linkTitle && (
                    <h3 className={s['CardOffice__ContactInfoTitle']}>
                        <a href={href} className={s['CardOffice__ContactInfoTitleLink']}>
                            {title}
                        </a>
                    </h3>
                )}

                {streetAddress && (
                    <p className={s['CardOffice__ContactInfoText']}>{streetAddress}</p>
                )}

                {zipCity && (
                    <p className={s['CardOffice__ContactInfoText']}>{zipCity}</p>
                )}

                {map && map.href && (
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={map.href}
                        className={classNames(s['CardOffice__ContactLink'], s['CardOffice__ContactLink--Location'])}>
                        {map.title}
                    </a>
                )}
                <div className={s['CardOffice__ContactInfo']}>
                    {email && (
                        <p>
                            <a
                                className={classNames(s['CardOffice__ContactLink'], s['CardOffice__ContactLink--Email'])}
                                href={'mailto:' + email}>
                                {email}
                            </a>
                        </p>
                    )}
                    {phone && (
                        <p>
                            <a
                                className={classNames(s['CardOffice__ContactLink'], s['CardOffice__ContactLink--Phone'])}
                                href={'tel:' + phone}>
                                {phone}
                            </a>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardOffice;
