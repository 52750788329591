/**
 * CardContactPerson
 */

import React from 'react';
import classNames from 'classnames';
import BackgroundImage from 'Components/BackgroundImage';
import s from './CardContactPerson.module.scss';

class CardContactPerson extends React.PureComponent {
    render() {
        const { name, email, phone, mobilePhoneNumber, image, title, places, href, departments } = this.props;

        const hasImage = image && image.src;

        return (
            <div className={s['CardContactPerson']}>
                {hasImage && (
                    <BackgroundImage
                        image={image}
                        className={s['CardContactPerson__Image']}
                    />
                )}
                <div className={s['CardContactPerson__Texts']}>
                    {name && (
                        <h3 className={s['CardContactPerson__Name']}>
                            <a className={s['CardContactPerson__Link']} href={href}>
                                {name}
                            </a>
                        </h3>
                    )}
                    {title && (
                        <p className={s['CardContactPerson__Title']}>{title}</p>
                    )}
                </div>
                <div className={s['CardContactPerson__Links']}>
                    {email && (
                        <a
                            className={classNames(s['CardContactPerson__Link'], s['CardContactPerson__Link--Email'])}
                            href={`mailto:${email}`}>
                            {email}
                        </a>
                    )}
                    {phone && (
                        <a
                            className={classNames(s['CardContactPerson__Link'], s['CardContactPerson__Link--Phone'])}
                            href={`tel:${phone}`}>
                            {phone}
                        </a>
                    )}

                    {mobilePhoneNumber && phone !== mobilePhoneNumber && (
                        <a
                            className={classNames(s['CardContactPerson__Link'], s['CardContactPerson__Link--MobilePhone'])}
                            href={`tel:${mobilePhoneNumber}`}>
                            {mobilePhoneNumber}
                        </a>
                    )}
                </div>

                <div className={s['CardContactPerson__Meta']}>
                    {departments && (
                        <div className={classNames(s['CardContactPerson__Column'], s['CardContactPerson__Column--Department'])}>
                            {departments.map((department, index) => (
                                <p
                                    className={classNames(s['CardContactPerson__Row'], s['CardContactPerson__Row--Department'])}
                                    key={index}>
                                    {department.name}
                                </p>
                            ))}
                        </div>
                    )}
                    {places && (
                        <div className={classNames(s['CardContactPerson__Column'], s['CardContactPerson__Column--Place'])}>
                            {places.map((place, index) => (
                                <p
                                    className={classNames(s['CardContactPerson__Row'], s['CardContactPerson__Row--Place'])}
                                    key={index}>
                                    {place.name}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

CardContactPerson.defaultProps = {
    image: {},
};

export default CardContactPerson;
