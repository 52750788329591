/**
 * CardContactPersonList
 */

import React from 'react';
import classNames from 'classnames';
import { httpGet } from 'utils/Http';
import { idToName, objToQuery } from 'utils/Helpers';
import CardContactPerson from 'Components/CardContactPerson';
import CardOffice from 'Components/CardOffice';
import ButtonReadMore from 'Components/ButtonReadMore';
import FilterSelect from 'Components/FilterSelect';
import AjaxLoader from 'Components/AjaxLoader';
import { dataLayerPush } from 'utils/Datalayer';
import CardContactPersonDetailed from 'Components/CardContactPersonDetailed';
import CardOfficeLarge from 'Components/CardOfficeLarge';
import convertObjectKeys from 'utils/SnakeCaseToCamelCase';
import Title from 'Components/Title';
import s from './CardContactPersonList.module.scss';

class CardContactPersonList extends React.PureComponent {
    state = {
        currentNumberOfCards: this.props.visible,
        mounted: false,
        fetching: false,
        hasFetched: false,
        office: this.props.office || '',
        selectedPlace: this.props.place || '',
        selectedCategory: this.props.selectedCategory || '',
        listType: this.props.listType || 'both',
        concepts: this.props.concepts || '',
        categories: this.props.categories || '',
        partners: this.props.partners || '',
        departments: this.props.departments || '',
        selectedBusinessConcepts: this.props.preselectedBusinessConcepts,
        selectedBusinessPartners: this.props.preselectedBusinessPartners,
    };

    fetch = (isInitialSearch, placeChanged = false, categoryChanged = false) => {
        const {
            selectedPlace,
            selectedCategory,
            office,
            selectedBusinessPartners,
            selectedBusinessConcepts,
            departments,
            listType,
            fetching,
        } = this.state;

        if(fetching) {
            return null;
        }

        this.setState({
            fetching: true,
            hasFetched: false,
        });

        const {
            pages,
            onlySpecificEmployees,
            currentPage,
            offices,
            callbackOnResult,
            baseUrl,
        } = this.props;

        let include = '';
        let includeOffices = '';

        if (pages) {
            include = pages.map((page) => page.id).join(',');
        }

        if (offices && (listType === 'both' || listType === 'offices')) {
            includeOffices = offices.map((office) => office.id).join(',');
        }

        const queries = objToQuery({
            exclude: currentPage || '',
            categories: selectedCategory,
            places: selectedPlace,
            office: office,
            include: include,
            includeOffices: includeOffices,
            listType: listType,
            specific: onlySpecificEmployees ? 'True' : 'False',
            departments: departments ? departments.join(',') : '',
            partners: selectedBusinessPartners ? selectedBusinessPartners.join(',') : '',
            concepts: selectedBusinessConcepts ? selectedBusinessConcepts.join(',') : '',
            fallbackToParentPlace: isInitialSearch ? false : true,
        });

        const url = `${baseUrl}/wt/api/v2/employees/filter/${queries}`;

        httpGet(url)
            .then((result) => {
                this.setState({
                    fetching: false,
                    cards: result,
                    hasFetched: true,
                });

                if (!isInitialSearch) {
                    const contactFilterCity = idToName(selectedPlace, this.props.places);
                    dataLayerPush({
                        'event': 'contactFilter',
                        'contactFilterCity': contactFilterCity,
                        'contactFilterCategory': selectedCategory
                    });
                }

                if (result && result.length) {
                    callbackOnResult();
                }

                // Check if the new state and the state triggered by fetch is same and otherwise trigger new fetch
                if(placeChanged && selectedPlace !== this.state.selectedPlace) {
                    this.fetch(false, true);
                }
                if(categoryChanged && selectedCategory !== this.state.selectedCategory) {
                    this.fetch(false, false, true);
                }
            })
            .catch(() => {
                this.setState({
                    hasFetched: true,
                    fetching: false,
                });
            });
    };

    componentDidMount = () => {
        if (typeof window !== 'undefined') {
            this.fetch(true);
        }
    }

    handleListExtension = () => {
        const self = this;

        this.setState({ currentNumberOfCards:
                this.state.currentNumberOfCards + this.props.visible }, () => {
            if (self.state.currentNumberOfCards === ((self.props.visible * 4))) {
                self.setState({
                    showShowAllButton: true,
                });
            }
        });
    };

    handleOnChangeCategory = (e) => {
        this.setState(
            {
                //selectedCategory: e ? e.map(data => data.value) : [], //Multi
                selectedCategory: e.value > 0 ? parseInt(e.value) : '',
                // fetching: true,
                // hasFetched: false,
            },
            () => {
                this.fetch(false, false, true);
            }
        );
    };

    handleOnChangePlace = (e) => {
        this.setState(
            {
                selectedPlace: e.value > 0 ? parseInt(e.value) : '',
                // fetching: true,
                // hasFetched: false,
            },
            () => {
                this.fetch(false, true);
            }
        );
    };

    render() {
        const {
            identifier,
            title,
            tag,
            places,
            categories,
            showFilter,
            pages,
            pageListUrl,
            extendable,
            hideCategoriesField,
        } = this.props;
        const {
            fetching,
            cards,
            selectedPlace,
            selectedCategory,
            currentNumberOfCards,
            showShowAllButton,
        } = this.state;

        const showShowMoreButton = !fetching && cards && cards.length > currentNumberOfCards;

        const classes = classNames(
            s['CardContactPersonList'],
            {[s['CardContactPersonList--Filter']]: showFilter && !pages.length},
        );

        const showOnePerson = this.props.listType === 'persons' && cards && cards.length === 1;
        const showOneOffice = this.props.listType === 'offices' && cards && cards.length === 1;

        return (
            <section className={classes}>
                <div className={s['CardContactPersonList__Container']}>
                    <Title title={title} tag={tag}>
                        {showFilter && !pages.length && (
                            <div className={s['CardContactPersonList__Options']}>
                                {places && (
                                    <div className={s['CardContactPersonList__Option']}>
                                        <FilterSelect
                                            id={`${identifier}-card-contact-person-list-place`}
                                            value={selectedPlace}
                                            placeholder="Välj plats"
                                            onChange={this.handleOnChangePlace}
                                            options={places.map((item) => ({
                                                value: item.id,
                                                label: item.name,
                                                isCounty: item.isCounty,
                                            }))}
                                            icon="Location"
                                            isLocation={true}
                                        />
                                    </div>
                                )}

                                {categories && !hideCategoriesField && (
                                    <div className={s['CardContactPersonList__Option']}>
                                        <FilterSelect
                                            id={`${identifier}-card-contact-person-list-category`}
                                            value={selectedCategory}
                                            placeholder="Välj kategori"
                                            onChange={
                                                this.handleOnChangeCategory
                                            }
                                            options={categories.map((item) => ({
                                                value: item.id,
                                                label: item.name,
                                            }))}
                                            icon="Category"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </Title>
                    {fetching && (
                        <div className={s['CardContactPersonList__LoaderWrapper']}>
                            <AjaxLoader />
                        </div>
                    )}
                    {showOnePerson && <div className={s['CardContactPersonList__SingleContainer']}>
                        <CardContactPersonDetailed {...cards[0]} />
                    </div>}
                    {showOneOffice && <div className={s['CardContactPersonList__SingleContainer']}>
                        <CardOfficeLarge {...convertObjectKeys(cards[0])} />
                    </div>}
                    {!fetching && cards && !showOnePerson && !showOneOffice && (
                        <div className={s['CardContactPersonList__List']}>
                            {cards.slice(0, currentNumberOfCards).map((item, index) => (
                                <div
                                    className={s['CardContactPersonList__Item']}
                                    key={`${index}-${item.id}`}
                                >
                                    {item.type === 'office'
                                        ? <CardOffice
                                            {...item}
                                            shadow={true}
                                            center={true}
                                            linkTitle={true}
                                        />
                                        : <CardContactPerson {...item} />}
                                </div>
                            ))}
                        </div>
                    )}

                    {!fetching && (!cards || !cards.length) && (
                        <h3 className={s['CardContactPersonList__NoHits']}>
                            {!pageListUrl ? (
                                'Vi hittade inga medarbetare eller kontor som matchar din sökning.'
                            ) : (
                                <React.Fragment>
                                    Vi hittade inga medarbetare eller kontor som matchar din sökning.
                                    Du kan alltid <a href={pageListUrl}>kontakta oss nationellt</a> så hjälper
                                    vi dig komma rätt.
                                </React.Fragment>
                            )}
                        </h3>
                    )}

                    {!showShowAllButton && showShowMoreButton && extendable && (
                        <div className={s['CardContactPersonList__ButtonWrapper']}>
                            <ButtonReadMore
                                text="Visa fler"
                                onClick={this.handleListExtension}
                            />
                        </div>
                    )}

                    {((showShowAllButton && pageListUrl)) && (
                        <div className={s['CardContactPersonList__ButtonWrapper']}>
                            <a
                                href={pageListUrl}
                                className={s['CardContactPersonList__GoToPage']}>
                            Sök fler
                            </a>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

CardContactPersonList.defaultProps = {
    identifier: '',
    image: {},
    link: {},
    cards: [],
    pages: [],
    baseUrl: '',
    callbackOnResult: () => {},
};

export default CardContactPersonList;
