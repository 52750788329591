/**
 * CardContactPersonDetailed
 */

import React from 'react';
import classNames from 'classnames';
import BackgroundImage from 'Components/BackgroundImage';
import s from './CardContactPersonDetailed.module.scss';

const CardContactPersonDetailed = ({
    image,
    name,
    title,
    office,
    email,
    phone,
    href,
    linkTitle,
    mobilePhoneNumber,
    info,
    showDescription
}) => (
    <div className={classNames(
        s['CardContactPersonDetailed'],
        {[s['CardContactPersonDetailed--HasImage']]: image && image.src },
    )}>
        {image && image.src && (
            <div className={s['CardContactPersonDetailed__ImageContainer']}>
                <BackgroundImage
                    image={image}
                    className={s['CardContactPersonDetailed__Image']}
                />
            </div>
        )}

        <div className={s['CardContactPersonDetailed__ContactInfoContainer']}>

            {name && !linkTitle && (
                <h1 className={s['CardContactPersonDetailed__ContactInfoTitle']}>
                    {name}
                </h1>
            )}

            {title && (
                <p className={s['CardContactPersonDetailed__WorkTitle']}>
                    {title}
                </p>
            )}
            {name && linkTitle && (
                <h3 className={s['CardContactPersonDetailed__ContactInfoTitle']}>
                    <a
                        className={s['CardContactPersonDetailed__ContactInfoTitleLink']}
                        href={href}>
                        {name}
                    </a>
                </h3>
            )}

            {showDescription && info && (
                <p className={s['CardContactPersonDetailed__ContactInfoPreamble']}>
                    {info}
                </p>
            )}

            <div className={s['CardContactPersonDetailed__ContactInfo']}>
                {office && (
                    <p className={classNames(s['CardContactPersonDetailed__ContactLinkWrapper'], s['CardContactPersonDetailed__ContactLinkWrapper--Office'])}>
                        <a
                            className={s['CardContactPersonDetailed__ContactLink']}
                            href={office.href}>
                            {office.title}
                        </a>
                    </p>
                )}
                {email && (
                    <p className={classNames(s['CardContactPersonDetailed__ContactLinkWrapper'], s['CardContactPersonDetailed__ContactLinkWrapper--Email'])}>
                        <a
                            className={s['CardContactPersonDetailed__ContactLink']}
                            href={'mailto:' + email}>
                            {email}
                        </a>
                    </p>
                )}
                {phone && phone.length > 5 && mobilePhoneNumber !== phone && (
                    <p className={classNames(s['CardContactPersonDetailed__ContactLinkWrapper'], s['CardContactPersonDetailed__ContactLinkWrapper--Phone'])}>
                        <a
                            className={s['CardContactPersonDetailed__ContactLink']}
                            href={'tel:' + phone}>
                            {phone}
                        </a>
                    </p>
                )}
                {mobilePhoneNumber && mobilePhoneNumber.length > 5 && (
                    <p className={classNames(s['CardContactPersonDetailed__ContactLinkWrapper'], s['CardContactPersonDetailed__ContactLinkWrapper--MobilePhone'])}>
                        <a
                            className={s['CardContactPersonDetailed__ContactLink']}
                            href={'tel:' + mobilePhoneNumber}>
                            {mobilePhoneNumber.replace('+46', '0')}
                        </a>
                    </p>
                )}
            </div>
        </div>
    </div>
);

export default CardContactPersonDetailed;
